<template>
    <div class="container">
        <div class="columns box-title is-12">
            <div class="column is-6">
                <h4 class="has-text-left">
                    <i class="fal fa-users"></i>
                    ຂໍ້ມູນລາຍລະອຽດຄະແນນ
                    <span class="countTotal">({{ count }})</span>
                </h4>
            </div>

        </div>
        <div class="box">
            <div class="columns">
                <div class="column is-2">
                    <div class="control">
                        <label class="label">ຈາກວັນທີ:</label>
                        <date-picker
                                format="DD/MM/YYYY"
                                value-type="YYYY-MM-DD"
                                type="date"
                                v-model="start_date"
                                :disabled-date="disabledAfterToday"
                                :clearable="false"
                                name="start_date"
                        ></date-picker>
                    </div>
                </div>
                <div class="column is-2">
                    <div class="control">
                        <label class="label">ຫາວັນທີ:</label>
                        <date-picker
                                format="DD/MM/YYYY"
                                value-type="YYYY-MM-DD"
                                type="date"
                                v-model="end_date"
                                :disabled-date="disabledAfterToday"
                                :clearable="false"
                                name="end_date"
                        ></date-picker>
                    </div>
                </div>
                <div class="column is-2" style="margin-top: 3px">
                    <div v-if="$can(['SuperAdmin', 'manager', 'marketing'])">
                        <vs-button
                                ref="button" flat
                                @click="printListPointDetail()"
                                class="form-btn-loading"
                                :loading="loading">
                            export excel
                        </vs-button>
                    </div>
                </div>
            </div>
            <hr :style="{marginTop: '-10px'}">
            <div class="center" :style="{marginTop: '-10px'}">
                <vs-table>
                    <template #thead>
                        <vs-tr>
                            <vs-th>ຈຳນວນຄະແນນທັງໝົດ</vs-th>
                            <vs-th>ຈຳນວນຄະແນນທີ່ໃຊ້</vs-th>
                        </vs-tr>
                    </template>
                    <template #tbody>
                        <vs-tr :is-selected="true">
                            <vs-td>{{ FormatPrice(totalPoint) }}</vs-td>
                            <vs-td>{{ FormatPrice(totalSpend) }}</vs-td>
                        </vs-tr>
                    </template>
                </vs-table>
            </div>
        </div>
        <div class="center fit-width">
            <vs-table ref="table">
                <template #thead>
                    <vs-tr>
                        <vs-th>ລຳດັບ</vs-th>
                        <vs-th>ວັນທີ</vs-th>
                        <vs-th>ຫົວຂໍ້</vs-th>
                        <vs-th>ຈາກ</vs-th>
                        <vs-th>ເຖິງ</vs-th>
                        <vs-th>ຈຳນວນຄະແນນ</vs-th>
                        <vs-th>ຄຳອະທິບາຍ</vs-th>
                        <vs-th>ປະເພດ</vs-th>
                        <vs-th>ສະຖານະ</vs-th>
                    </vs-tr>
                </template>
                <template #tbody>
                    <vs-tr
                            :key="index"
                            :data="customer"
                            v-for="(customer, index) in $vs.getPage(customersPoint, page, max)">
                        <vs-td>{{ index + 1 }}</vs-td>
                        <vs-td>{{ moment(customer.created_at).format('DD/MM/YYYY H:mm') }}</vs-td>
                        <vs-td>{{ la_title[customer.point_type] }}</vs-td>
                        <vs-td>{{ customer.from_name }}</vs-td>
                        <vs-td>{{ customer.to_name }}</vs-td>
                        <vs-td>{{ FormatPrice(customer.amount) }}</vs-td>
                        <vs-td>{{ customer.description }}</vs-td>
                        <vs-td v-if="customer.transaction_type === 'earn' && customer.transaction_status === 'confirm'"
                               :style="{color: 'green'}">
                            {{ la_type[customer.transaction_type] }}
                        </vs-td>
                        <vs-td :style="{color: 'blue'}" v-else>{{ la_type[customer.transaction_type] }}</vs-td>

                        <vs-td v-if="customer.transaction_status === 'confirm'"
                               :style="{color: 'green'}">
                            {{ la_status[customer.transaction_status] }}
                        </vs-td>
                        <vs-td :style="{color: 'blue'}" v-else>{{ la_status[customer.transaction_status] }}</vs-td>
                    </vs-tr>
                </template>
                <template #footer>
                    <pagination v-if="pagination.last_page > 1" :pagination="pagination" :offset="offset"
                                @paginate="FetchData"></pagination>
                    <Loading v-if="isLoading"/>
                </template>
            </vs-table>
        </div>
        <CustomerExcelTemplate v-show="false" ref="ExportExcel"/>
    </div>
</template>
<script>
import Loading from "@/views/Loading";
import {mapActions} from "vuex";
import pagination from '@/Plugins/Paginations/Pagination';
import * as helper from "@/Helpers/GetValue";
import CustomerExcelTemplate from "@coms/printing/CustomerExcelTemplate";
import DatePicker from "vue2-datepicker";
import moment from "moment";

export default {
    components: {
        Loading,
        pagination,
        CustomerExcelTemplate,
        DatePicker
    },

    data: () => ({
        page: 1,
        max: 10,
        start_date: moment(new Date()).format('YYYY-MM-DD'),
        end_date: moment(new Date()).format('YYYY-MM-DD'),
        isLoading: true,
        customer_type: '',
        branch: '',
        listBranches: [],
        register_type: '',
        customersPoint: [],
        searchItem: "",
        oldVal: "",
        totalPoint: 0,
        totalSpend: 0,
        // Pagination
        count: 0,
        offset: 10,
        limit_page: 10,
        pagination: {},

        la_title: {
            'deposit parcel': 'ຝາກພັດສະດຸ',
            'receive parcel': 'ຮັບພັດສະດຸ',
            'register': 'ລົງທະບຽນລູກຄ້າໃໝ່',
            'exchange item': 'ແລກຂອງລາງວັນ',
            'lao national day 2021': 'ວັນຊາດ 2021',
            'event point 17/12': 'ກິດຈະກຳແຈກຄະແນນ',
            'event point christmas 2021': 'ກ່ອງສຸ່ມ',
            'point pay': 'ຈ່າຍພ້ອຍ',
            'transfer point': 'ໂອນພ້ອຍ',
            'receive point': 'ຮັບພ້ອຍ',
            'phone top up': 'ຕື່ມມູນຄ່າໂທ',
            'lottery': 'ຊື້ເລກ',
            'i-point': 'i-point',
            'roll back point': 'ຖືກຖອນພ້ອຍ',
            'referral register': 'ແນະນຳລູກຄ້າໃໝ່ລົງທະບຽນ',
            'open lucky box': 'ເປີກກ່ອງສຸ່ມ',
            'point top up': 'ຕື່ມເງິນ',
            'point cash out': 'ຖອນເງິນ',
            'cash out fee': 'ຄ່າທຳນຽມການຖອນເງິນ',
        },
        la_type: {
            'earn': 'ໄດ້ຮັບຄະແນນ',
            'spend': 'ນຳໃຊ້ຄະແນນ'
        },
        la_status: {
            'confirm': 'ຢືນຢັນ',
            'cancel': 'ຍົກເລີກ'
        },

        //Export
        actualSelectedItems: [],
        isSelecting: false,
        pageInvoices: [],
        loading: false,
        checkedAll: false,
    }),
    watch: {
        'searchItem': function (value) {
            if (value === '') {
                this.FetchData();
            }
        },
        'start_date': function () {
            this.pagination.current_page = 1;
            this.FetchData();
        },
        'end_date': function () {
            this.pagination.current_page = 1;
            this.FetchData();
        },
    },
    methods: {
        printListPointDetail() {
            const loading = this.BtnLoading();
            this.loading = true;
            this.$http.post('/print/list/point/detail/' + this.$route.params.id, {
                start_date: this.start_date,
                end_date: this.end_date,
                print: 'yes',
                isAdmin: 'yes',
            }, {responseType: 'blob'}).then((res) => {
                const fileUrl = window.URL.createObjectURL(new Blob([res.data]))
                const fileLink = document.createElement('a');
                fileLink.href = fileUrl;
                if (this.start_date === this.end_date) {
                    fileLink.setAttribute('download', 'ລາຍການຄະແນນວັນທີ ' + moment(this.start_date).format('DD-MM-YYYY') + '.xlsx');
                } else {
                    fileLink.setAttribute('download', 'ລາຍການຄະແນນວັນທີ ' + moment(this.start_date).format('DD-MM-YYYY') + 'ຫາ' + moment(this.end_date).format('d-m-Y') + '.xlsx');
                }
                document.body.appendChild(fileLink);
                fileLink.click();
                document.body.removeChild(fileLink);
                this.loading = false;
                loading.close()
            }).catch(() => {
                this.loading = false;
                loading.close()
            });
        },

        FormatPrice(price) {
            let p = (parseInt(price) || 0).toFixed(2).split(".");
            return p[0].split("").reverse().reduce(function (acc, price, i) {
                return price === "-" ? acc : price + (i && !(i % 3) ? "," : "") + acc;
            }, "") + " ຄະແນນ";
        },
        disabledAfterToday(date) {
            const today = new Date();
            today.setHours(0, 0, 0, 0);

            return date > today;
        },
        FetchData() {
            this.isLoading = true;
            this.fetchInvoiceItemsResponse().then((res) => {
                setTimeout(() => {
                    this.customersPoint = res.data.point_transaction.data;
                    this.totalPoint = res.data.total_point_earn;
                    this.totalSpend = res.data.total_point_spend;
                    this.count = res.data.point_transaction.total;
                    this.pagination = res.data.point_transaction;
                    this.isLoading = false;
                    this.oldVal = ''
                }, 300);
            }).catch(() => {
                this.isLoading = false;
            });
        },

        fetchInvoiceItemsResponse(options = {}) {
            const {current_page, per_page} = options;
            return this.$http.get('list/point/detail/' + this.$route.params.id, {
                params: {
                    start_date: this.start_date,
                    end_date: this.end_date,
                    isAdmin: 'yes',
                    page: current_page ?? this.pagination.current_page,
                    per_page: per_page ?? this.limit_page,
                }
            })
        },
        FilterBySearch() {
            helper.GetOldValueOnInput(this);
        },
        ...mapActions({
            LogoutUser: "User/destroyToken",
        }),

        BtnLoading() {
            return this.$vs.loading({
                target: this.$refs.table,
                scale: "1",
                background: "primary",
                opacity: 1,
                color: "#195BFF",
            });
        },
    },
    created() {
        this.FetchData();
    },
};
</script>
<style lang="scss" scope>
.container {
  margin-top: 40px;

  .title-login {
    font-size: 20px;
    color: $primary-color;
  }
}

.fit-width {
  white-space: nowrap;
}

.vs-table__td,
.vs-table__th {
  vertical-align: middle !important;
  white-space: nowrap !important;
  width: 1px !important;
}

</style>
